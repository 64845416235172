import { render, staticRenderFns } from "./Honor.vue?vue&type=template&id=65eb12cb&scoped=true&"
import script from "./Honor.vue?vue&type=script&lang=js&"
export * from "./Honor.vue?vue&type=script&lang=js&"
import style0 from "./Honor.vue?vue&type=style&index=0&id=65eb12cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65eb12cb",
  null
  
)

export default component.exports