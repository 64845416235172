<!-- 企业理念 -->
<template>
  <div class="Concept">
    <div class="topText">
      <div class="title">{{ concept.title }}</div>
    </div>
    <!-- 内容区域 -->
    <div class="content">
      <div
        v-for="(item, index) in concept.steps"
        :key="index"
        class="concept-item"
      >
        <img :src="item.img" alt="">
        <div class="item-content">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      concept: {
        title: '企业理念',
        steps: [
          {
            title: '诚信合作 共赢未来',
            img: 'https://static.zhongjiadata.com/images/20211029/7a9bce387343489b926a9aac90a27c9f.png',
            content: '中嘉数科本着诚信、专业、开放的态度，积极对外开展合作，乐于打破闭门造车的行业现状，以共赢的态度发展企业'
          }, {
            title: '以人为本 能者为师',
            img: 'https://static.zhongjiadata.com/images/20211029/af750ac5ddf14e139cf927f128bf79fd.png',
            content: '公司以人性化的管理开展经营，凡公司的各项制度，工作流程均让员工参与制定，切实让员工感受到公司是真正的大家庭'
          }, {
            title: '数字孪生 创新发展',
            img: 'https://static.zhongjiadata.com/images/20211029/69efebaf27da4e3395766775a98e331c.png',
            content: '公司以数据为核心，数字孪生、以人为本为理念朝着智慧城市建设为目标，全力拓展市场，研发心产品，树立高新企业标杆'
          }
        ]
      }
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.Concept {
  margin-bottom: 10px;
  .topText {
    height: 200px;
  }
  .content {
    margin: 0 auto;
    width: 1280px;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    .concept-item {
      width: 418px;
      height: 356px;
      position: relative;
      .item-content {
        width: 418px;
        height: 356px;
        position: absolute;
        top: 0;
        left: 0;
        padding: 80px 40px;
        .title {
          width: 100%;
          line-height: 36px;
          font-size: 36px;
          font-weight: 700;
          text-align: center;
          color: #ffffff;
          margin-bottom: 60px;
        }
        .content {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 36px;
        }
      }
    }
  }
}
</style>
