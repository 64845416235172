<template>
  <div class="list">
    <div class="topText">
      <div class="title">大事记</div>
    </div>
    <div class="conBox">
      <img class="imgBlock" :src="imgList[active]" alt="-">
      <div class="listBox">
        <!-- 时间线区域 -->
        <div class="timeline" :style="{ top: moveHeight + 'px' }">
          <div
            v-for="(item, index) in eventList"
            :key="index"
            :ref="'item'"
            class="timeline-item"
            :class="active === index ? 'active' : ''"
            @click="clickAction(index)"
          >
            <!-- 左侧时间 -->
            <div class="left-box">
              <div class="time">{{ item.time }}</div>
            </div>
            <!-- 中间分割连接线 -->
            <div class="center-line">
              <div class="point-box" />
              <div v-show="index !== (eventList.length - 1)" class="line" />
            </div>
            <!-- 右侧标题内容 -->
            <div class="right-box">
              <div class="title">{{ item.title }}</div>
              <div class="content">
                <div v-for="(o, i) in item.content" :key="i">{{ o }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'List',
  components: {},
  data() {
    return {
      eventList: [
        {
          time: '2020.02',
          title: '成立',
          content: [
            `依托多年的行业积累和优秀的创始团队，中嘉成立于杭州市萧山区，注册资金2018万。`
          ]
        },
        {
          time: '2020.04',
          title: '积累',
          content: [
            `中嘉参与杭州西湖大学校区项目数字化建设`,
            `中嘉与中法航空大学新校区等多所高校项目的数字化建设达成合作意向`,
            `打开江西市场,成立江西省办事处`,
            `参与鹰潭市科创小镇、文化艺术中心,赣州市艺术文化馆,南昌大广高速等建设项目`
          ]
        },
        {
          time: '2020.09',
          title: '合作',
          content: [
            `中嘉与鹰潭市国有控股集团开展增资扩股,注册资金增加至3105万元`,
            `中嘉与浙江大学、浙江理工大学等高校开展校企合作,实现人才互通,挂牌成立实践基地`
          ]
        },
        {
          time: '2021.01',
          title: '转型',
          content: [
            `中嘉调整组织架构,完善制度标准,明确产品定位及业务方向,扩充研发队伍`,
            `凭借良好的社会资源与客户口碑,成功扩展数字园区、未来社区、数字乡村、智慧校园等智慧空间和智慧交通、智慧工地以及政企数字化等智慧城市领域`,
            `正式更名为中嘉数字科技`
          ]
        },
        {
          time: '2021.02',
          title: '发展',
          content: [
            `广募良才。核心团队成员均来自华东勘测院、浙大院、中建等大型工程企业和中电、阿里、海康等行业头部,项目经验丰富,实操能力强`,
            `打造数字化合作生态。先后与阿里云、中电莱斯、通信服中浙信等实力团队联合进行项目履约`,
            `技术落地。城建投企业智慧云平台、数字园区综合管控平台等自主研发产品相继交付，联合或独立推出一系列行业整体解决方案`,
            `完善企业资质。先后通过了ISO9001质量体系认证，企业信用等级AAA级认证，通过认定科技型中小企业，杭州市雏鹰企业等`
          ]
        },
        {
          time: '未来',
          title: '数字赋能未来生活',
          content: [`中嘉数科坚持以ToG、ToB驱动ToC，以满足人民对美好生活的向往和需求为最终目标，致力于成为空间智慧管理、城市/乡村数字化治理整体解决方案提供商`]
        }
      ],
      imgList: [
        'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/list1.png',
        'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/list2.png',
        'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/list3.png',
        'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/list4.png',
        'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/list5.png',
        'https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/list6.png'
      ],
      active: 0,
      moveHeight: 200,
      timer: null
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    clickAction(index) {
      this.active = index;
      let h = 0;
      for (let i = 0; i < index; i++) {
        h += this.$refs.item[i].offsetHeight;
      }
      this.moveHeight = -(h - 200);
      if (index === 0) {
        this.moveHeight = 200;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.list {
  .topText {
    height: 197px;
  }
  .conBox {
    position: relative;
    width: 100%;
    height: 800px;
    // font-family: 'siyuan-light' !important;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .listBox {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: 1280px;
      height: calc(100% - 60px);
      color: #fff;
      z-index: 200;
      margin-top: 30px;
      margin-bottom: 30px;
      overflow: hidden;
      .timeline {
        position: relative;
        transition: top 0.3s;
        top: 0;
        .timeline-item {
          cursor: default;
          display: flex;
          height: 100%;
          opacity: 0.5;
          .left-box {
            width: 140px;
            text-align: right;
            font-weight: 500;
            text-align: right;
            color: #ffffff;
            .time {
              font-size: 20px;
              line-height: 20px;
              // transform: translateY(-5px);
            }
          }
          .center-line {
            width: 9px;
            margin-left: 40px;
            margin-right: 40px;
            display: flex;
            flex-direction: column;
            // position: relative;
            // top: 7px;
            .point-box {
              width: 9px;
              height: 9px;
              border-radius: 50%;
              margin-top: 5px;
              background: #e5e9f4;
            }
            .line {
              width: 1px;
              flex: 1;
              background: #e5e9f4;
              margin-left: 4px;
              margin-top: 5px;
              // margin-bottom: 5px;
            }
          }
          .right-box {
            flex: 1;
            .title {
              text-align: left;
              margin-bottom: 32px;
              font-size: 24px;
              line-height: 24px;
              height: 24px;
              font-weight: 400;
              color: #ffffff;
            }
            .content {
              margin-bottom: 60px;
              width: 480px;
              font-size: 15px;
              color: #ffffff;
              line-height: 24px;
              font-family: 'siyuan-light' !important;
              div {
                margin-bottom: 50px;
                font-family: 'siyuan-light' !important;
                position: relative;
                &::before {
                  content: '';
                  width: 6px;
                  height: 6px;
                  border-radius: 3px;
                  background-color: #fff;
                  display: block;
                  position: absolute;
                  top: 8px;
                  left: -12px;
                }
              }
            }
          }
        }
        .active {
          opacity: 1;
          .left-box {
            .time {
              font-size: 28px;
              line-height: 28px;
              font-weight: 700;
              color: #538dfc;
              // transform: translateY(-10px);
            }
          }
          .center-line {
            .point-box {
              background: #538dfc;
            }
          }
          .right-box {
            .title {
              font-weight: 700;
              color: #538dfc;
            }
          }
        }
      }
    }
  }
}
</style>
