<template>
  <div class="video">
    <div class="topText">
      <div class="title">企业视频</div>
    </div>
    <div
      class="videoBox"
      @click="palyVideo"
    >
      <!-- <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/about/video.jpg"> -->
      <video
        id="video"
        class="video"
        controls="controls"
        loop="loop"
        src="https://static.zhongjiadata.com/web/assets/about.mp4"
        poster="https://static.zhongjiadata.com/web/assets/about/video.jpg"
      />
      <!-- <video
        id="videoElement"
        class="video"
        controls="controls"
        loop="loop"
        playsinline="false"
        webkit-playsinline="false"
        preload="none"
        src="/web/assets/about.mp4"
        poster="https://static.zhongjiadata.com/web/assets/about/video.jpg"
      /> -->
      <!-- <video id="video" ref="singleVideo" class="video" autoplay controls /> -->
      <!-- <div id="mse" class="videoArea" /> -->
    </div>
  </div>
</template>
<script>
// import flvjs from 'flv.js';
// import videojs from 'video.js';
// import Player from 'xgplayer';
// import './.xgplayer/skin/index.js';
export default {
  data() {
    return {
      isPlay: false
    };
  },
  mounted() {
    // this.palyVideo();
    // this.getVideo();
    // setTimeout(() => {
    //   new Player({
    //     id: 'mse',
    //     autoplay: false,
    //     playsinline: false,
    //     preload: 'none',
    //     url: '/web/assets/about.mp4'
    //   });
    // }, 2000);
  },
  methods: {
    ajax(url, cb) {
      console.log('ajax111');
      const xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob'; // "text"-字符串 "blob"-Blob对象 "arraybuffer"-ArrayBuffer对象
      xhr.onload = function() {
        cb(xhr.response);
      };
      xhr.send();
    },
    // 使用切片方式播放
    // palyVideo() {
    //   const _this = this;
    //   const video = document.querySelector('video');
    //   // 视频资源存放路径，假设下面有5个分段视频 video1.mp4 ~ video5.mp4，第一个段为初始化视频init.mp4
    //   const assetURL = '/web';
    //   // 视频格式和编码信息，主要为判断浏览器是否支持视频格式，但如果信息和视频不符可能会报错
    //   const mimeCodec = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
    //   if ('MediaSource' in window && MediaSource.isTypeSupported(mimeCodec)) {
    //     const mediaSource = new MediaSource();
    //     video.src = URL.createObjectURL(mediaSource); // 将video与MediaSource绑定，此处生成一个Blob URL
    //     mediaSource.addEventListener('sourceopen', sourceOpen); // 可以理解为容器打开
    //   } else {
    //     // 浏览器不支持该视频格式
    //     console.error('Unsupported MIME type or codec: ', mimeCodec);
    //   }

    //   function sourceOpen() {
    //     const mediaSource = this;
    //     const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
    //     let i = 1;
    //     function getNextVideo(url) {
    //       // ajax代码实现翻看上文，数据请求类型为arraybuffer
    //       _this.ajax(url, function(buf) {
    //         console.log('buf', buf);
    //         // 往容器中添加请求到的数据，不会影响当下的视频播放。
    //         sourceBuffer.appendBuffer(buf);
    //       });
    //     }
    //     // 每次appendBuffer数据更新完之后就会触发
    //     sourceBuffer.addEventListener('updateend', function() {
    //       if (i === 1) {
    //         // 第一个初始化视频加载完就开始播放
    //         video.play();
    //       }
    //       if (i < 1) {
    //         // 一段视频加载完成后，请求下一段视频
    //         getNextVideo(`${assetURL}/video00${i}.mp4`);
    //       }
    //       if (i === 1) {
    //         // 全部视频片段加载完关闭容器
    //         mediaSource.endOfStream();
    //         URL.revokeObjectURL(video.src); // Blob URL已经使用并加载，不需要再次使用的话可以释放掉。
    //       }
    //       i++;
    //     });
    //     // 加载初始视频
    //     getNextVideo(`${assetURL}/video000001.mp4`);
    //   }
    // },
    // 使用西瓜播放器
    // getVideo() {
    //   this.singlePlayer = videojs(this.$refs.singleVideo, {
    //     autoplay: true,
    //     controls: false
    //   });
    //   const res = 'http://127.0.0.1:5500/playlist.m3u8';
    //   if (res) {
    //     this.singlePlayer.src({ src: res, type: 'application/x-mpegURL' });
    //     this.singlePlayer.play();
    //   }
    // }
    // 使用video直接播放
    palyVideo() {
      if (!!window.ActiveXObject || 'ActiveXObject' in window) {
        const video = document.getElementById('video');
        if (!this.isPlay) {
          video.play();
        } else {
          video.pause();
        }
        this.isPlay = !this.isPlay;
      } else {
        return;
      }
    }
    // 使用flv.js播放
    // palyVideo() {
    //   if (flvjs.isSupported()) {
    //     const videoElement = document.getElementById('videoElement');
    //     const flvPlayer = flvjs.createPlayer({
    //       type: 'mp4',
    //       isLive: true,
    //       hasAudio: false,
    //       url: '/web/assets/about.mp4'
    //     });
    //     console.log(flvPlayer, 'flv对象');
    //     flvPlayer.attachMediaElement(videoElement);
    //     flvPlayer.load();
    //     flvPlayer.play();
    //   }
    // },
  }
};
</script>
<style lang="scss" scoped>
.video {
  .topText {
    height: 218px;
    // .title {
    //   padding-top: 103px;
    // }
  }
  .videoBox {
    margin: 0 auto;
    width: 1280px;
    height: 560px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    .videoArea {
      width: 100%;
      height: 100%;
    }
    .video {
      // border-radius: 10px;
      object-fit: cover;
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        & {
          border-radius: 0px;
        }
        .video {
          width: 100%;
          height: auto;
          position: relative;
          left: 0;
          top: -70px;
          transform: translate(-1%, -1%);
          object-fit: fill;
        }
      }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
