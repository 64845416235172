<template>
  <div class="wrapper pageBox">
    <div class="banner">
      <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/banner.png">
      <div class="bannerText">
        <div class="title">企业介绍</div>
        <div class="btn"><div class="company">中嘉数科</div><div class="colLine" />数字赋能未来生活</div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="topText company">
      <div class="title">公司简介</div>
    </div>
    <div class="companyText">
      <div class="left">
        <!-- <div class="title">公司简介</div> -->
        <div class="text">中嘉数字科技（浙江）有限公司位于杭州市萧山区，是一家国资控股、提供全流程数字化转型升级解决方案的科技公司。我们以“数字赋能未来生活”为发展理念，致力于用数字化手段推动民生与健康服务、产业经济与企业服务、政府效能与治理、环境与低碳的发展，基于嘉服云整体架构，打造空间集成管控运维服务和数字化政企民服务两大集成产品体系。秉持着“以人为本，客户至上”的经营理念，逐步积累了智能化设计实施、物联网平台研发与系统集成、数字孪生可视化等关键技术经验和相关资质，业务涵盖了智慧园区、智慧病房、未来社区、智慧停车等空间科技服务领域和政企服务、企业服务的数字化改革领域。中嘉数科旨在切实解决空间与城市管理、民生与产业服务中关键性的痛点问题，促进数字化发展革新，打造产业新生态。</div>
      </div>
      <div class="right">
        <img src="https://static.zhongjiadata.com/images/20211101/f811100601db46ca9f05c71f7cae8d3f.png">
      </div>
    </div>
    <!-- 企业视频 -->
    <Video />
    <!-- 大事记 -->
    <List />
    <!-- 企业理念 -->
    <Concept />
    <!-- 荣誉资质 -->
    <Honor />
    <!-- 生态伙伴 -->
    <!-- <Companion /> -->
  </div>
</template>
<script>
import Video from './components/video.vue';
import List from './components/list.vue';
import Concept from './components/Concept.vue';
import Honor from './components/Honor.vue';
// import Companion from './components/Companion.vue';
export default {
  components: { Video, List, Concept, Honor }
};
</script>
<style lang="scss" scoped>
.btn {
  width: 251px !important;
  .company {
    color: #2C6EFF;
  }
}
.topText.company {
  height: 200px;
}
.companyText {
  // padding-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1280px;
  margin: 0 auto;
  .left {
    width: 602px;
    z-index: 1;
    .title {
      margin-top: 4px;
      margin-bottom: 23px;
      color: #333;
      font-size: 40px;
      font-family: 'siyuan-medium' !important;
    }
    .text {
      color: #6A6E77;
      font-size: 16px;
      line-height: 28px;
      text-indent: 32px;
    }
  }
  .right {
    // margin-left: -44px;
    img {
      width: 638px;
      height: 416px;
    }
  }
}
</style>
